import React from 'react';

import SEO from '../components/SEO';

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
  </>
);
export default NotFoundPage;
